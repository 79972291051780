import React from 'react'
import right_img from '../assets/Images/Portfolio-hero-img.svg'
import icon_1 from '../assets/Icons/Mid-Cap-Funds-icon.svg'
import icon_2 from '../assets/Icons/Index-Funds-icon.svg'
import icon_3 from '../assets/Icons/Arbitrage-Funds-icon.svg'
import icon_4 from '../assets/Icons/Multi-Cap-Funds-icon.svg'
import icon_5 from '../assets/Icons/Small-Cap-Funds-icon.svg'
import icon_6 from '../assets/Icons/ELSS-Funds-icon.svg'
import { Link } from 'react-router-dom'
function PortfolioHero() {
  return (
    <>
                    {/* First section */}
        <section className='portfolio_bg_img xl:mt-3'>
            <div className='container py-10 md:py-16'>
                <div className='xl:w-1/2 md:w-9/12'>
                    <h1 className='lg:text-5xl md:text-3xl text-2xl pb-2 text-white font-bold nunito'>Portfolio Management Services (PMS)</h1>
                    <p className='lg:text-lg text-sm md:text-base pb-6 text-white inter font-normal xl:leading-6'>Portfolio Management Services (PMS) offer personalized and professional management of your investment portfolio. A team of expert advisors and portfolio managers work to design and implement investment strategies that align with your financial objectives and risk appetite.</p>
                    <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
                </div>
            </div>
        </section>
                {/* second section */}
        <section className='container py-10 md:py-16 lg:py-20 md:flex gap-4'>
            {/* Left side */}
            <div className='flex flex-col xl:gap-4 md:w-3/4 xl:mt-12 gap-2'>
                <h2 className='text-[#3C3C3C] lg:text-4xl text-2xl font-bold nunito'>What are Portfolio Management Services?</h2>
                <p className='text-[#6A6A6A] text-base font-normal inter'>Portfolio Management Services (PMS) are professional investment services that help individuals manage their investment portfolios. PMS providers offer personalized investment strategies, asset allocation, and ongoing monitoring to optimize returns and manage risks.</p>
                <p className='text-[#6A6A6A] text-base font-normal inter'>With PMS, investors can delegate the management of their portfolios to experienced professionals while enjoying personalized advice and regular performance reporting.</p>
            </div>
            {/* Right side */}
            <div className='py-10 sm:py-0 '>
                <img src={right_img} alt="right_img" className='w-full h-full' />
            </div>
        </section>
              {/* Third section */}
          {/* <section className='container bg-[#F5F5F5] pt-10 flex flex-col justify-center items-center'>
                    <h3 className='text-[#323232] nunito xl:text-4xl text-2xl font-bold '>Curated Collections</h3>
                <div className='grid sm:grid-cols-3 grid-cols-2 gap-x-1 gap-y-6 py-10 xl:gap-x-36 xl:gap-y-12 items-center'>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_1} alt="icon_1"/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Mid Cap</p>
                    </div>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_2} alt="icon_1"/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Large Cap</p>
                    </div>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-3 xl:gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_3} alt="icon_1" className='w-9 h-9'/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Small Cap</p>
                    </div>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_4} alt="icon_1"/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Multi Cap Fund</p>
                    </div>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_5} alt="icon_1"/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Small Cap</p>
                    </div>
                    <div className='xl:w-[189px] xl:h-[108px] w-40 h-24 flex flex-col gap-1 items-center justify-center hover:bg-white hover:rounded-lg hover:shadow-lg'>
                        <img src={icon_6} alt="icon_1"/>
                        <p className='text-[#323232] xl:text-lg text-base font-semibold plus-jakarta-sans'>Tax-saving Option</p>
                    </div>
                </div>
          </section>     */}
    </>
  )
}

export default PortfolioHero