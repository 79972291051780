import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="lg:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const  FdFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: " How does the interest rate on Fixed Deposits work?",
      answer: "Interest on Fixed Deposits remains fixed during the deposit period. The interest is compounded on a quarterly, half-yearly, or yearly basis, depending upon the bank's policy.",
    },
    {
      question: "Are Fixed Deposits insured?",
      answer: "In many countries, fixed deposits are insured up to a certain limit by government agencies or deposit insurance schemes, providing security for your principal amount.",
    },
    {
      question: "What are the tax implications of Fixed Deposits?",
      answer: "Interest earned from fixed deposits is added to your income and taxed as per the income tax slab that you fall into. Tax-saving fixed deposits may offer tax benefits under certain conditions that have a lock-in period.",
    },
    {
      question: "How can I choose the right Fixed Deposit?",
      answer: "Consider factors such as interest rate, tenure, flexibility of premature withdrawal, and credibility of the institution. Cheerbull can help you compare these options to find the right FD for you.",
    },
    {
      question: "How does Cheerbull help with Fixed Deposit investments?",
      answer: "Cheerbull offers you an extensive selection of fixed deposit options across banks and other financial institutions, ensuring interest rates are competitive, and customized advice will point you in the right direction toward choosing an optimal FD.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default  FdFaq;
