import React from 'react'
import { Link } from 'react-router-dom'
function JoinUs() {
  return (
    <section className='tryour_bg'>
        <div className='container py-10 xl:py-16'>
            <h1 className='text-white pb-4 text-2xl md:text-3xl xl:text-5xl font-bold nunito text-center'>Start your Wealth Journey with Us</h1>
            <p className='inter text-center xl:px-52 text-sm xl:text-base font-normal text-white'>Begin your path to financial success with us, where expert guidance meets personalized wealth strategies. Let's build your prosperous future together.</p>
            <Link to="https://clientlogin.cheerbull.com/" target="_blank">
            <div className='flex justify-center pt-8 xl:pt-10'>
                  <button className='bg-custom sign_btn py-3 px-8 text-lg font-bold roboto text-white rounded-lg '>
                  <span>Get Started</span></button>
            </div>
            </Link>
        </div>
    </section>
  )
}

export default JoinUs