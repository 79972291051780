import React from 'react'
import InsuranceHero from '../Components/InsuranceHero'
import InsuranceJoinUs from '../Components/InsuranceJoinUs'
import Testimonials from '../Components/Testimonials'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import Inputform from '../Components/Inputform'
import InsuranceFaq from '../Components/InsuranceFaq'


function Insurance() {
  return (
   <>
     <InsuranceHero/>
     <InsuranceJoinUs/>
     <Inputform/>
     <FeaturedOnTwo/>
     <InsuranceFaq/>
     <Testimonials/>
   </>
  )
}

export default Insurance