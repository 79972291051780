import React from 'react'
import AboutHero from '../Components/AboutHero'
import OurValues from '../Components/OurValues'
import JoinUs from '../Components/JoinUs'
import Committed from '../Components/Committed'
import Testimonials from '../Components/Testimonials'
import CEOdetails from '../Components/CEOdetails'

function AboutPage() {
  return (
    <>
       
        <AboutHero/>
        <CEOdetails/>
        <OurValues/>  
        <JoinUs/>
        {/* <Committed/> */}
        
        <Testimonials/>
       
    </>
  )
}

export default AboutPage