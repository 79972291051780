import React from 'react'
import img_1 from '../assets/Images/BenifitofP2P-left-img.webp'

function BenifitOfP2P() {
  return (
    <>
    <section className='typesofaif_bg'>
    <div className='container pt-12'>
            <h1 className='xl:text-4xl lg:text-3xl text-2xl font-bold nunito text-white text-center pb-2'>Benefits of Peer-to-Peer Lending (P2P)</h1>
            <div className='grid md:grid-cols-3 gap-7 py-8 lg:py-16'>
                <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Rapid Approval</p>
                    <p className='text-base font-normal inter'>Get Your Loan Approved Fast</p>
                </div>
                <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Penalty-Free Prepayment</p>
                    <p className=' text-base font-normal inter'>Pay Back Early with Zero Penalties</p>
                </div>
                <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Cost-Effective Borrowing</p>
                    <p className=' text-base font-normal inter'>Enjoy Lower Interest Rates</p>
                </div>
            </div>
    </div>
    </section>

    <section className='py-10 lg:py-20'>
        <div className='container md:flex gap-10 xl:justify-between'>
            <div className=''>
                <img src={img_1}/>
            </div>
            <div className='flex flex-col justify-center w-full mt-6 sm:mt-0'>
                <h3 className='xl:text-4xl text-2xl font-bold nunito text-[#3C3C3C]'>How P2P Lending Works</h3>
                <p className='text-base font-normal inter text-[#6A6A6A] py-4'>Investors create a profile that includes their investment preferences, such as the loan types they want to fund and the amount they’re willing to invest. Once the borrower accepts, investors receive monthly payments consisting of principal and interest, minus any fees charged by the lending platform.</p>
                <p className='text-base font-normal inter text-[#6A6A6A]'>Borrowers create a profile, too, however, they must provide personal and financial information such as their credit score, income and the purpose of the loan. Once the profile is complete, borrowers can create a loan listing that outlines their borrowing needs.</p>
            </div>
        </div>
    </section>
</>
  )
}

export default BenifitOfP2P