import React from 'react'
import Navbar from '../Components/Navbar'
import MarketLinkedHero from '../Components/MarketLinkedHero'
import TypesofMLD from '../Components/TypesofMLD'
import AdvantagesOfMld from '../Components/AdvantagesOfMld'
import HowMldWork from '../Components/HowMldWork'
import JoinUs from '../Components/JoinUs'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'

import Testimonials from '../Components/Testimonials'
import MldFaq from '../Components/MldFaq'


function MarketLinked() {
  return (
    <>
        
        <MarketLinkedHero/>
        <TypesofMLD/>
        <AdvantagesOfMld/>
        <HowMldWork/>
        <JoinUs/>
        <FeaturedOnTwo/>
        <MldFaq/>
        <Testimonials/>
        
    </>
  )
}

export default MarketLinked