import React from 'react'
import img_1 from '../assets/Images/P2P-hero-right-img.webp'
import { Link } from 'react-router-dom'
function P2Phero() {
  return (
    <>
    <section className='p2p_hero_bg xl:mt-3'>
         <div className='container py-10 xl:py-24'>
            <div className='lg:w-3/5 md:w-4/5'>
                 <h1 className=' xl:text-5xl text-2xl md:text-3xl  text-white font-bold nunito'>Peer-to-Peer Lending (P2P)</h1>
                 <p className='xl:text-lg text-sm md:text-base py-2  text-white inter font-normal'>Peer-to-Peer Lending (P2P) are digital transactions between two individuals. This type of mobile banking allows funds to be transferred directly from one person's bank account, checking account, credit or debit card, or payment app to another person's bank account or app.</p>
                 <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
            </div>
         </div>
     </section>

     <section className='container bg-white md:flex xl:gap-16 py-6 md:py-10 lg:py-16'>
                 {/* Left section */}
                 <div className='flex flex-col justify-center'>
                     <h1 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito'>What is a Peer-to-Peer Lending?</h1>
                     <p className='text-base font-normal text-[#6A6A6A] inter py-3'>Peer-to-Peer (P2P) lending enables individuals to obtain loans directly from other individuals, cutting out the financial institution as the middleman. Websites that facilitate P2P lending have greatly increased their adoption as an alternative method of financing.</p>
                     <p className='text-base font-normal text-[#6A6A6A] inter'>P2P lending is also known as “social lending” or “crowd lending.” It has only been around since 2005, but the crowd of competitors already includes Prosper, Lending Club, Upstart, and Funding Circle.</p>
                 </div>
                 {/* Right side */}
                 <div className='py-6 xl:w-4/5 xl:py-0'>
                     <img src={img_1} className=''/>
                 </div>
     </section>

 </>
  )
}

export default P2Phero