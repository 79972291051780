import React from 'react'
import img_1 from '../assets/Images/Insurance-hero-right.webp'
import img_2 from '../assets/Images/Insurance-hero-left.webp'
import InvestmentPlatformfor from '../Components/InvestmentPlatformfor'
import { Link } from 'react-router-dom'


function InsuranceHero() {
  return (
    <>
         <section className='insurance_hero_bg xl:mt-3'>
         <div className='container py-8 lg:py-16'>
            <div className='xl:w-3/5 md:w-4/5'>
                 <h1 className='xl:text-5xl text-3xl  text-white font-bold nunito'>Insurance</h1>
                 <p className='xl:text-lg text-sm md:text-base py-2  text-white inter font-normal'>CHEERBULL offers a wide array of insurance products designed to protect every aspect of your life. Whether it's safeguarding your health, home, vehicle, or business, we have a solution tailored to meet your specific needs. Our comprehensive insurance solutions are designed to offer financial protection against life's uncertainties, ensuring you can face the future with confidence.</p>
                 <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
            </div>
         </div>
     </section>

     <section className='container bg-white md:flex md:gap-6 xl:gap-16 py-6 md:py-8 lg:py-20'>
                 {/* Left section */}
                 <div className='flex flex-col justify-center'>
                     <h1 className='xl:text-4xl text-2xl md:text-2xl lg:text-3xl font-bold nunito'>Secure Your Future with Cheerbull's Comprehensive Insurance Solutions</h1>
                     <p className='text-base font-normal text-[#6A6A6A] inter py-3'> We understand the importance of safeguarding your future and providing peace of mind for you and your loved ones. Our comprehensive insurance solutions are designed to offer financial protection against life's uncertainties, ensuring you can face the future with confidence.</p>
                     {/* <p className='text-base font-normal text-[#6A6A6A] inter'>Corporate bonds can be issued by both public and private companies and are typically traded in the bond market.</p> */}
                 </div>
                 {/* Right side */}
                 <div className='py-6 xl:w-full xl:py-0'>
                     <img src={img_1} className=''/>
                 </div>
     </section>

     <section className=''>
        <div className='container md:flex gap-10 xl:justify-between md:pb-8 lg:pb-20'>
            <div className='xl:w-full'>
                <img src={img_2}/>
            </div>
            <div className='flex flex-col justify-center mt-8 sm:mt-0'>
                <h3 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito text-[#3C3C3C]'>Comprehensive Insurance Coverage</h3>
                <p className='text-base font-normal inter text-[#6A6A6A] py-4'>Our health insurance covers medical expenses, ensuring access to top facilities, while our property insurance protects your home and assets from risks like fire and theft. Drive confidently with our auto insurance, which covers accidents and third-party liability. For businesses, we provide tailored solutions to secure against unforeseen events.</p>
                <p className='text-base font-normal inter text-[#6A6A6A] py-4'>Additionally, our travel insurance ensures safe and worry-free journeys. Benefit from our expert consultation, hassle-free claims process, and competitive premiums for comprehensive and affordable protection.</p>
            </div>
        </div>
    </section>

 
 
    </>
  )
}

export default InsuranceHero