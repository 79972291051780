import React from 'react'
import SuccessHero from '../Components/SuccessHero'
import BenefitofWill from '../Components/BenefitofWill'
import JoinUs from '../Components/JoinUs'
import FeaturedOn from '../Components/FeaturedOn'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import Testimonials from '../Components/Testimonials'
import SuccesswillInputForm from '../Components/SuccesswillInputForm'

function SuccessandWillPage() {
  return (
    <>
        <SuccessHero/>
        <BenefitofWill/>
        <SuccesswillInputForm/>
        <JoinUs/>
        <FeaturedOnTwo/>
        <Testimonials/>
    </>
  )
}

export default SuccessandWillPage