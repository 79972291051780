import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="xl:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const MutualFundFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: " What is loan against Mutual Funds?",
      answer: " Loan against mutual funds (LAMF) allows you to borrow cash against your mutual fund investments as collateral. You can use Volt Money to lien mark your mutual funds digitally to avail an instant limit without losing the ownership of your mutual funds and all the associated benefits with it. Funds will be made available in the form of an overdraft facility. You can utilize the required amount and repay anytime without any prepayment charges. Interest is charged only on the utilized amount and for the duration the funds are utilized. You can select from a list of 4500+ approved mutual funds from different asset management companies (AMCs) in India. You can lien mark mutual funds registered with both CAMS & KFintech, Registrars & Transfer Agents (RTAs). We recommend digital loan against mutual funds to meet any of your financial requirements like travel, gadget purchase, balance transfer for your high interest loans, medical emergency.",
    },
    {
      question: "What are the conditions to get a loan from CHEERBULL?",
      answer: " To be eligible for loan against mutual funds with CHEERBULL, you should be between 18 and 65 years of age :- Your entire mutual fund portfolio eligible for loan against mutual funds, should not be less than ₹50,000, Loan against mutual funds is available for mutual funds approved with CAMS & Kfintech (RTAs). Debt & ELSS funds (units held greater than 3 years) are also eligible for loan against mutual funds, Loan against mutual funds is also available for joint holdings with AS / ES (anyone survivor / either or survivor) mode of holding, To avail loan against mutual funds, you should be an Indian resident,There is no minimum credit score and income requirement for loan against mutual funds.",
    },
    {
      question: "How much money can I borrow as a loan?",
      answer: "You can choose to set up a credit line amount ranging from Rs. 25,000 to Rs. 1 crore for loan against mutual funds with CHEERBULL. Yes! You read it right, you can avail a credit line up to Rs.1 crore digitally in 5 minutes.",
    },
    {
      question: "What are the documents required?",
      answer: "The entire process is 100% digital, so there is no requirement for any physical documents for loan against mutual funds. To ensure a smooth and quick processing, please have the following information handy: PAN card number,Email ID associated with CAMS and KFintech (for mutual fund verification),Bank account details for account verification and receiving the loan amount,Debit card or net banking details for setting up the mandate.",
    },
    {
      question: "What is lien marking/pledging of Mutual Funds?",
      answer: "When you opt for a loan against your mutual fund units, Volt Money digitally lien mark/pledge your mutual fund units in the favor of the lender. This ensures that the units cannot be redeemed or sold until the loan amount is repaid. The lien marking process is conducted entirely digitally and in real-time for loan against mutual funds.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl lg:text-3xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default MutualFundFaq;
