import React from 'react'
import left_image from '../assets/Images/AdvantageofMLD-left-img.webp'
function AdvantagesOfMld() {
  return (
    <section>
    <div className='container py-16 xl:py-24 md:flex md:gap-8'>
            <div className='pb-12 sm:pb-0'>
                <img src={left_image}/>
            </div>
            <div className='  '>
                <h1 className='nunito xl:text-4xl text-2xl font-bold text-[#3C3C3C] pb-10 md:pb-5 lg:pb-10'>Advantages of Market-Linked Debentures (MLD’s)</h1>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C] '>Downside protection</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>Principal protected MLDs offer investors the benefit of downside protection, as they guarantee the return of the initial investment amount at maturity.</p>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Potential for higher returns</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>Non-principal protected MLDs provide the potential for higher returns compared to traditional fixed-income investments.</p>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Diversification</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>This helps spread risk and reduce exposure to any single investment, potentially improving the overall risk-return profile of the investment portfolio.</p>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Customization</p>
                <p className='inter text-base font-normal text-[#6A6A6A]'>Investors can choose from a variety of MLD structures, such as those with principal protection, currency exposures, or tailored coupon payments based on specific conditions.</p>

            </div>
    </div>
   </section>
  )
}

export default AdvantagesOfMld