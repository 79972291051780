import img_icon from '../assets/Icons/customer-img-icon.svg'
import img_icon_2 from '../assets/Icons/customer2-img-icon.svg'
import img_icon_3 from '../assets/Icons/customer3-img-icon.svg'
import img_icon_4 from '../assets/Icons/customer4-img-icon.svg'
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

function Testimonials() {
    return (
        <>
            <section className="bg-white">
                <div className="container pt-20 pb-32">
                    <h1 className="xl:text-4xl lg:text-3xl text-2xl text-center font-bold nunito">Testimonials</h1>
                    <p className="text-sm xl:text-base text-[#6A6A6A] inter text-center pt-2 pb-8 xl:pb-16">
                        Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio,
                    </p>

                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        loop={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                       
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className='xl:w-[420px] xl:h-80 bg-white px-5 py-3 border-2 shadow-md rounded-xl '>
                                <p className='inter font-normal text-base text-[#444444]'>The breadth of offerings on CHEERBULL, including corporate bonds and tax-free bonds, has been fantastic. The platform’s ease of use and comprehensive approach make it an essential tool for anyone serious about financial planning.</p>
                                <img src={img_icon} className="h-10 w-10 mt-6" />
                                <p className="font-medium asap text-lg text-[#3D3D3D]">Rudra Singh</p>
                                <p className="text-sm text-[#652623] uppercase font-semibold asap">investing with cheerbull</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='xl:w-[420px] xl:h-80 bg-white px-5 py-3 border-2 shadow-md rounded-xl '>
                                <p className='inter font-normal text-base text-[#444444]'>CHEERBULL has simplified my investment journey with its wide range of services. From managing mutual funds to handling fixed deposits and insurance, everything is accessible on one platform. It’s been instrumental in helping me reach my financial goals.</p>
                                <img src={img_icon_2} className="h-10 w-10 mt-6" />
                                <p className="font-medium asap text-lg text-[#3D3D3D]">Vishal Sharma</p>
                                <p className="text-sm text-[#652623] uppercase font-semibold asap">investing with cheerbull</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='xl:w-[420px] xl:h-80 bg-white px-5 py-3 border-2 shadow-md rounded-xl '>
                                <p className='inter font-normal text-base text-[#444444]'>With CHEERBULL, managing my investments and insurance has never been easier. The platform’s intuitive design and diverse services, from goal calculators to portfolio tracking, have made financial management straightforward and effective</p>
                                <img src={img_icon_3} className="h-10 w-10 mt-6" />
                                <p className="font-medium asap text-lg text-[#3D3D3D]">Ramesh Kumar</p>
                                <p className="text-sm text-[#652623] uppercase font-semibold asap">investing with cheerbull</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='xl:w-[420px] xl:h-80 bg-white px-5 py-3 border-2 shadow-md rounded-xl '>
                                <p className='inter font-normal text-base text-[#444444]'>CHEERBULL’s holistic approach to investment and financial services is impressive. Whether I need to track my portfolio or explore loan options against mutual funds, everything I need is right at my fingertips.</p>
                                <img src={img_icon_4} className="h-10 w-10 mt-6" />
                                <p className="font-medium asap text-lg text-[#3D3D3D]">Sushmita Roy</p>
                                <p className="text-sm text-[#652623] uppercase font-semibold asap">investing with cheerbull</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='xl:w-[420px] xl:h-80 bg-white px-5 py-3 border-2 shadow-md rounded-xl '>
                                <p className='inter font-normal text-base text-[#444444]'>I’m thrilled with CHEERBULL’s seamless integration of diverse financial services. From demat and trading to succession planning, the platform covers all my financial needs, making it a top choice for anyone looking to streamline their investments.</p>
                                <img src={img_icon_3} className="h-10 w-10 mt-6" />
                                <p className="font-medium asap text-lg text-[#3D3D3D]">Prabhakar Dutta</p>
                                <p className="text-sm text-[#652623] uppercase font-semibold asap">investing with cheerbull</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Testimonials;
