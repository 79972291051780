import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="xl:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const InsuranceFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What types of insurance can I explore with Cheerbull?",
      answer: "Starting from health insurance and life insurance to general insurance, we have them all here at Cheerbull. Be it your health, your family's future, or your assets, we have planned options to secure them.",
    },
    {
      question: " How can I determine the right insurance plan for me?",
      answer: "The appropriate insurance plan will be based on your particular needs and goals. Our expert advisors can help you choose one based on coverage requirements, budget, and other considerations like personal situation. Get a tailored consultation from Cheerbull to help you find the best.",
    },
    {
      question: "Can I get insurance coverage for my business through Cheerbull?",
      answer: "Yes, Cheerbull offers business-oriented packages in property, liability, and employee benefits insurance. Our team is at your beck to work hand in hand in tailoring a special plan that takes care of the unique risks of your business so that you can grow with confidence.",
    },
    {
      question: "What steps should I take if I need to update my insurance policy?",
      answer: "In case of any update in your insurance policy, contact Cheerbull's customer support. We would be happy to help you increase your coverage limits, update your beneficiaries, or modify any other terms of your policy. We will make sure that your policy is updated to reflect your latest needs and circumstances.",
    },
    {
      question: "What happens if I miss a premium payment on my insurance policy?",
      answer: "Missing just one premium can affect your coverage status. The advisors at Cheerbull will work with you to understand what's going on and can discuss reinstating your policy or setting up a payment plan. Addressing missed payments early will help avoid a coverage lapse.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl lg:text-3xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default InsuranceFaq;
