 import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="lg:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const  AifFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Who can invest in AIFs?",
      answer: " AIFs are for high net worth individuals (HNIs), institutional investors and sophisticated investors. They have higher minimum investment requirements and are not suitable for retail investors due to complexity and risk profile.",
    },
    {
      question: "How are AIFs regulated?",
      answer: " AIFs are regulated by financial authorities which may vary by country. In India for example AIFs are regulated by SEBI. They need to comply with disclosure, transparency and investor protection norms.",
    },
    {
      question: "Can AIFs be used for tax planning?",
      answer: "  AIFs themselves are not tax saving instruments but can be part of a larger investment strategy that includes tax planning. Tax implications will depend on the AIF and the jurisdiction’s tax laws.",
    },
    {
      question: "Are AIFs regulated?",
      answer: "Yes, AIFs are regulated by financial authorities which ensures transparency and protection to investors. In India they are regulated by SEBI.",
    },
    {
      question: "How can Cheerbull help me with AIF investments?",
      answer: "Cheerbull can guide you on AIF investments, help you navigate the complexities and identify opportunities that suit your financial goals. Our team provides end to end support including portfolio management, risk assessment and customized investment strategies.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default  AifFaq;
