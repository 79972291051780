import React from 'react'
import Navbar from '../Components/Navbar'
import SovereignGoldHero from '../Components/SovereignGoldHero'
import BenefitsofSGB from '../Components/BenefitsofSGB'
import ImplicationsofSGB from '../Components/ImplicationsofSGB'
import JoinUs from '../Components/JoinUs'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'

import Testimonials from '../Components/Testimonials'
import Fotter from '../Components/Fotter'
import OurProductInputForm from '../Components/OurProductInputForm'
import GoldFaq from '../Components/GoldFaq'

function SovereignGoldBond() {
  return (
   <>
   
    <SovereignGoldHero/>
    <BenefitsofSGB/>
    <ImplicationsofSGB/>
    <JoinUs/>
    <OurProductInputForm/>
    <FeaturedOnTwo/>
    <GoldFaq/>
    <Testimonials/>
   
   </>
  )
}

export default SovereignGoldBond