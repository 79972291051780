import React from 'react'

function BenefitsofCorFd() {
  return (
   <section className='typesofaif_bg'>
    <div className='container py-12'>
            <h1 className='lg:text-4xl text-2xl font-bold nunito text-white text-center pb-2'>Benefits of Fixed Deposit</h1>
            <div className='grid md:grid-cols-3 gap-7 lg:py-16 py-8'>
                <div className='flex flex-col justify-center w-full h-48 md:h-60 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='lg:text-3xl text-xl font-bold nunito'>Fixed returns</p>
                    <p className='text-base font-normal inter'>Fixed Deposit have a fixed rate of return, giving
                    you certainty about your investment returns.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 md:h-60 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' lg:text-3xl text-xl font-bold nunito'>Higher interest returns</p>
                    <p className=' text-base font-normal inter'>Fixed Deposit usually offer higher returns compared
                    to savings accounts, offering improved returns on your investment.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 md:h-60 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' lg:text-3xl text-xl font-bold nunito'>Diversification</p>
                    <p className=' text-base font-normal inter'>Investing in Fixed Deposit offers diversification
                    benefits, as they are a safe option in pursuit of returns.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenefitsofCorFd