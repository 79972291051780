import './App.css';
import Router from './Router';

function App() {
  return (
   <>
    <Router/>
   </>
  );
}

export default App;
