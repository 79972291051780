import React from 'react'
import Demathero from '../Components/Demathero'
import BenefitsofDemat from '../Components/BenefitsofDemat'
import BestInsurance from '../Components/BestInsurance'
import JoinUs from '../Components/JoinUs'
import FeaturedOn from '../Components/FeaturedOn'
import Testimonials from '../Components/Testimonials'
import DemateFaq from '../Components/DemateFaq'

function DematPage() {
  return (
    <>
     <Demathero/>
     <BenefitsofDemat/>
     <BestInsurance/>
     <JoinUs/>
     <FeaturedOn/>
     {/* <DemateFaq/>  */}
     <Testimonials/>
    </>
  )
}

export default DematPage