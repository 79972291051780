import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="xl:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const P2PFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What Are the Benefits of Investing in P2P Lending?",
      answer: "P2P lending potentially offers higher returns than a traditional savings account or bonds. This also offers an opportunity to diversify an investment portfolio by lending to individuals or businesses across various sectors.",
    },
    {
      question: "How Is My Investment Protected?",
      answer: "Though P2P lending is associated with risks, most of the platforms incorporate credit assessment models, diversification of loans, and recovery processes to protect your investment. At Cheerbull, we believe in transparency and risk management for the protection of investments.",
    },
    {
      question: "Can I Withdraw My Investment Before the Loan Term Ends?",
      answer: "Most P2P lending platforms, Cheerbull included, have a fixed loan term that cannot be withdrawn earlier. You will, however, very often be able to sell your investment in a secondary market, if available, subject to the rules of the platform and market conditions.",
    },
    {
      question: "What Should I Do if a Borrower Defaults on Their Loan?",
      answer: " In case of default, recovery processes are usually triggered by the platform. On such tough instances, Cheerbull offers you support for the recovery effort and keeps you updated on the status of defaulted loans. We also offer tips on the best way to manage such situations.",
    },
    {
      question: "How Can I Start Investing in P2P Lending with Cheerbull?",
      answer: "First of all, you need to log in at Cheerbull and open an account. Once you are a registered user, you may start browsing through the already listed P2P lending opportunities and choose your investments to begin earning returns.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default P2PFaq;
