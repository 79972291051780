import React from 'react'

function BenefitsofSGB() {
  return (
    <section className='typesofaif_bg'>
    <div className='container py-12'>
            <h1 className='xl:text-4xl text-3xl font-bold nunito text-white text-center pb-12'>Benefits of SGBs</h1>
            <div className='grid md:grid-cols-3 gap-7 md:py-5 xl:py-16'>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-72 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Interest Income</p>
                    <p className='text-base font-normal inter'>SGBS offer an assured interest rate of 2.50% per annum payable semi-annually on the invested amount.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-72 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Diversification</p>
                    <p className=' text-base font-normal inter'>SGBS allow investors to diversify their portfolio by adding an asset class (gold) that has a low correlation with other investments like stocks and bonds.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-72 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Potential Wealth Creation</p>
                    <p className=' text-base font-normal inter'>Investing in gold through SGBs provides an opportunity for capital appreciation as the price of gold tends to rise over time.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenefitsofSGB