import React from 'react'
import carrer_img from '../assets/Images/Posp-career-img.webp'
function WhyisPOSp() {
  return (
    <section>
        <div className='container xl:py-20 xl:flex gap-8'>
         {/* Left part */}
             <div className='flex flex-col justify-center'>
                    <h1 className='text-[#3C3C3C] xl:text-[40px] font-bold nunito pb-4'>Why is POSp the best career?</h1>
                <ul className='list-disc nunito text-lg font-medium text-[#3C3C3C] pl-5 py-3'>
                    <li>POSp keeps exploring multiple opportunities and possibilities to achieve greater goals.</li>
                    <li className='mt-3'>POSp is entitled to engage with multiple insurance companies to compare the best policy and deliver it to the customer. This entitlement is open for all policies approved under IRDAI.</li>
                    <li className='mt-3'>POSp provides an easy use online portal for faster delivery of services to customers.</li>
                    <li className='mt-3'>There is no need for underwriting with POSp.</li>
                    <li className='mt-3'>As a single point of contact between the customer and company, you can improve and maintain customer relationships for renewals.</li>
                </ul> 
                <button className='sign_btn text-lg font-bold roboto bg-custom px-8 py-2 mt-4 rounded-lg text-white w-fit '>
                    <span>Register Now</span>
                </button>      
             </div>
             {/* Right part */}
             <div1>
                <img src={carrer_img}/>
             </div1>
        </div>
    </section>
  )
}

export default WhyisPOSp