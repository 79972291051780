import React from 'react'
import left_image from '../assets/Images/AssociatedWithFd-left-img.webp'


function AssociatedWithFd() {
  return (
    <section>
    <div className='container py-16 xl:py-24 md:flex md:gap-10 xl:gap-16'>
            <div className='xl:w-3/5'>
                <img src={left_image}/>
            </div>
            <div className='flex flex-col justify-center  '>
                <h1 className='nunito xl:text-[40px] text-2xl lg:text-3xl font-bold text-[#3C3C3C] xl:pb-7'>Risks associated with Fixed Deposit</h1>
                <p className='nunito text-xl xl:text-2xl font-bold text-[#3C3C3C] mt-4 xl:mt-0'>Credit risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-5'>There is a potential risk of the issuing company being unable to meet its financial obligations, resulting in a loss of investment or delayed interest payments.</p>
                <p className='nunito text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Re-investment risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-5'>Fluctuations in interest rates, especially if interest rates fall, result in lower returns upon reinvestment.</p>
                <p className='nunito text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Inflation risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-5'>Fixed returns from corporate FDs may not keep pace with inflation, reducing their real value over time.</p>
                <p className='nunito text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Liquidity risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A]'>Corporate FDs may have limited liquidity, making it difficult to withdraw funds before maturity.</p>
            </div>
    </div>
   </section>
  )
}

export default AssociatedWithFd