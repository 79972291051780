import React from 'react'
import BecomeaPartnerhero from '../Components/BecomeaPartnerhero'
import PartnerJoinUs from '../Components/PartnerJoinUs'
import Committed from '../Components/Committed'
import OurPartner from '../Components/OurPartner'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import Testimonials from '../Components/Testimonials'
import Preferred from '../Components/Preferred'
import ReferandSubBroker from '../Components/ReferandSubBroker'
import BecomePartnerform from '../Components/BecomePartnerform'
import FaQ from '../Components/FaQ'


function BecomeaPartner() {
  return (
    <>
      <BecomeaPartnerhero/>
      {/* <PartnerJoinUs/> */}
      <Preferred/>
      {/* <Committed/> */}
      <ReferandSubBroker/>
      <OurPartner/>
      <BecomePartnerform/>
      <FeaturedOnTwo/>
      {/* <FaQ/> */}
      <Testimonials/>
    </>
  )
}

export default BecomeaPartner