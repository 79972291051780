import React from 'react'
import Navbar from '../Components/Navbar'
import CorporateFdHero from '../Components/CorporateFdHero'
import BenefitsofCorFd from '../Components/BenefitsofCorFd'
import AssociatedWithFd from '../Components/AssociatedWithFd'
import JoinUs from '../Components/JoinUs'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import Testimonials from '../Components/Testimonials'
import OurProductInputForm from '../Components/OurProductInputForm'
import FdFaq from '../Components/FdFaq'


function CorporateFD() {
  return (
   <>
   
    <CorporateFdHero/>
    <BenefitsofCorFd/>
    <AssociatedWithFd/>
    <JoinUs/>
    <OurProductInputForm/>
    <FeaturedOnTwo/>
    <FdFaq/>
    <Testimonials/>
    
   </>
  )
}

export default CorporateFD