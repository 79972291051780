import React from 'react'
import img_1 from '../assets/Images/About-Us-our-valued-img.webp'
import img_2 from '../assets/Images/About-Us-our-valued-img-2.webp'
import icon from '../assets/Icons/About-Us-valued-icon.svg'
function OurValues() {
  return (
   <section className='bg-[#E4E4E4]'>
    <div className='container pt-16 pb-6'>
            <h1 className='text-center font-bold nunito text-2xl xl:text-4xl text-[#3C3C3C]'>Our Values/We Stands For</h1>
            <p className='inter text-xs xl:text-base font-normal text-center pt-2 text-[#6A6A6A]'>Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio,</p>
            <div className='md:flex justify-between gap-10 pt-6 md:pt-10 xl:pt-16'>
                <div className='xl:w-1/2 rounded-xl'>
                    <img src={img_1} alt="" className=''/>
                </div>
                <div className='xl:w-1/2 flex flex-col justify-evenly py-8 md:py-0 xl:py-0'>
                    <div className='flex gap-5'>
                        <img src={icon} className='xl:h-7 xl:w-7 w-6 h-6 '/>
                        <div>
                            <p className='xl:text-2xl text-xl nunito font-bold text-[#3C3C3C]'>Customer Excellence</p>
                            <p className='inter text-base font-normal text-[#6A6A6A]'>The new-age technologies summed up with our commitment and dedication has made us a trusted partner to our clients </p>
                        </div>
                    </div>
                    <div className='flex gap-5 py-6 xl:py-0'>
                        <img src={icon} className='xl:h-7 xl:w-7 w-6 h-6 '/>
                        <div>
                            <p className='xl:text-2xl text-xl nunito font-bold text-[#3C3C3C]'>Technology Driven</p>
                            <p className='inter text-base font-normal text-[#6A6A6A]'>We have introduced a new podium for tech-savvy millennials and Genz that are venturing into the financial marketplace. </p>
                        </div>
                    </div>
                    <div className='flex gap-5'>
                        <img src={icon} className='xl:h-7 xl:w-7 w-6 h-6 '/>
                        <div>
                            <p className='xl:text-2xl text-xl nunito font-bold text-[#3C3C3C]'>Transparency</p>
                            <p className='inter text-base font-normal text-[#6A6A6A]'>We believe in transparency at every step of your investment process.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='md:flex justify-between gap-10 py-6 md:py-10 xl:pt-16 '>
                <div className='xl:w-1/2 md:w-full flex flex-col  justify-evenly pb-8 md:pb-0 xl:pb-0'>
                    <h2 className='text-[#3C3C3C] xl:text-4xl text-2xl font-bold nunito '>Why Start Investing?</h2>
                    <p className='inter text-base font-normal text-[#6A6A6A] xl:-mt-9'>Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio, and execute transactions seamlessly with no extra charges. Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio, and execute transactions seamlessly with no extra charges</p>
                    <h3 className='xl:text-2xl text-lg font-bold nunito text-[#3C3C3C] pt-4 xl:pt-0'>24x7 Reporting</h3>
                    <p className='inter xl:-mt-12 text-sm xl:text-base font-normal text-[#6A6A6A]'>A comprehensive reporting of your portfolio</p>
                    <h3 className='xl:text-2xl text-lg font-bold nunito text-[#3C3C3C] pt-4 xl:pt-0'>Your own Technology</h3>
                    <p className='inter xl:-mt-12 text-sm xl:text-base font-normal text-[#6A6A6A]'>A self learning portal that guides you and knows what’s best for you</p>
                    <h3 className='xl:text-2xl text-lg font-bold nunito text-[#3C3C3C] pt-4 xl:pt-0'>Partner Support</h3>
                    <p className='inter xl:-mt-12 text-sm xl:text-base font-normal text-[#6A6A6A]'>A registered and trained partner available at your service 24×7</p>
                </div>
                <div className='xl:w-1/2'>
                    <img src={img_2} alt="" className='' />
                </div>
            </div>
    </div>
   </section>
  )
}

export default OurValues