import React from 'react'
import img_1 from '../assets/Images/Demat-hero-right-bg.webp'
import { Link } from 'react-router-dom'
function Demathero() {
  return (
    <>
       <section className='demat_hero_bg xl:mt-3'>
            <div className='container py-10 xl:py-14'>
               <div className='xl:w-8/12 md:w-4/5'>
                    <h1 className='xl:text-5xl text-2xl md:text-3xl  text-white font-bold nunito'>What are Equity & Demat?</h1>
                    <p className='xl:text-lg text-sm md:text-base py-2  text-white inter font-normal'>Equity refer to various investment instruments that provide ownership in a company. These products include individual stocks, exchange-traded funds (ETFs), intraday trading, futures and options, and more.</p>
                    <p className='xl:text-lg text-sm md:text-base pb-2  text-white inter font-normal'>Equity Shares are investment instruments that represent ownership in a company.
                    A Demat account offers numerous benefits, including safe storage of securities electronically, reducing the risk of theft or loss. It simplifies the transfer of securities without needing physical documents and enables faster processing and settlement of transactions.</p>
                    <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 lg:mt-3 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
               </div>
            </div>
        </section>

        <section className='container bg-white md:flex md:gap-5 xl:gap-16 py-6 md:py-10 xl:py-16'>
                    {/* Left section */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='xl:text-4xl text-2xl md:text-3xl font-bold nunito'>What are Equity & Demat?</h1>
                        <p className='text-base font-normal text-[#6A6A6A] inter py-3'>Equity & Demat refer to various investment instruments that provide ownership in a company. These products include individual stocks, exchange-traded funds (ETFs), intraday trading, futures and options, and more.</p>
                        <p className='text-base font-normal text-[#6A6A6A] inter'>Equity & Demat allow investors to participate in the ownership of companies, offering the potential for capital appreciation and dividends.</p>
                    </div>
                    {/* Right side */}
                    <div className='py-6 xl:w-4/5 xl:py-0'>
                        <img src={img_1} className=''/>
                    </div>
        </section>

    </>

        

  )
}

export default Demathero