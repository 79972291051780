import React from 'react'

function BenefitsofPOSP() {
  return (
    <section className='typesofaif_bg'>
        <div className='container py-20'>
                <h1 className='text-center text-white xl:text-[40px] font-bold nunito'>Benefits of becoming Probus Point Of Sale<br/> Person (Insurance Agent/advisor)</h1>
                <div className='grid xl:grid-cols-3 gap-7 pt-16'>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='text-3xl font-bold nunito'>Unlimited earning potential</p>
                    <p className='text-base font-normal inter'>There is no bar limit set to your income. Your earnings will be in the form of commissions from every policy that you sell.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' text-3xl font-bold nunito'>Entitlement for rewards</p>
                    <p className=' text-base font-normal inter'>Being a productive POS will entitle you to earn through rewards which are set periodically and also make you win accolades.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' text-3xl font-bold nunito'>Grow your Career</p>
                    <p className=' text-base font-normal inter'>We provide you with multiple options to grow in your career, based on your performance. You can build your team of agents and master up your sales.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' text-3xl font-bold nunito'>Convenience to work</p>
                    <p className=' text-base font-normal inter'>Being a POS we give you the liberty to make use of technology and tools that will help is easy calculations and improvise sales.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' text-3xl font-bold nunito'>Sales Platform</p>
                    <p className=' text-base font-normal inter'>As in the world of digitalization we provide you with fast track service of processing insurance policies over a few clicks throughout portals. Hence you don't make trips to customers and office.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' text-3xl font-bold nunito'>Managerial Support</p>
                    <p className=' text-base font-normal inter'>you are not alone to sell the policies, to ensure you are on the right track you will be coordinating with a dedicated supervisor who will assist you at every stage.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BenefitsofPOSP