import React from 'react'
import img_1 from '../assets/Images/Shares-hero-right-img.webp'
import { Link } from 'react-router-dom'
function SharesHero() {
  return (
    <>
       <section className='shares_hero_bg xl:mt-3'>
            <div className='container py-10 xl:py-24'>
               <div className='lg:w-3/5 md:w-4/5'>
                    <h1 className='lg:text-3xl xl:text-5xl text-2xl   text-white font-bold nunito'>Do not sell your Mutual Funds for any fund requirements!<br/> Avail a loan against them.</h1>
                    <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn lg:mt-6 px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
               </div>
            </div>
        </section>

        <section className='container bg-white md:flex lg:gap-16 py-6 md:py-10 xl:py-16'>
                    {/* Left section */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='xl:text-4xl text-2xl md:text-2xl lg:text-3xl font-bold nunito'>What is a loan against mutual funds?</h1>
                        <p className='text-base font-normal text-[#6A6A6A] inter py-3'>A loan against a mutual fund is a type of borrowing that allows individuals to avail of a loan from a lender by pledging their mutual fund units as collateral.</p>
                        <p className='text-base font-normal text-[#6A6A6A] inter'>A loan against mutual funds can be a convenient way to meet short-term financial needs without liquidating investments. However, it's essential to consider the risks involved, especially the potential for a margin call if the value of the mutual fund units declines.</p>
                    </div>
                    {/* Right side */}
                    <div className='py-6 xl:w-4/5 xl:py-0'>
                        <img src={img_1} className=''/>
                    </div>
        </section>

    </>
  )
}

export default SharesHero