import React from 'react'
import icon_1 from '../assets/Icons/Perferred-icon-1.svg'
import icon_2 from '../assets/Icons/Perferred-icon-2.svg'
import icon_3 from '../assets/Icons/Perferred-icon-3.svg'
import { NavLink } from 'react-router-dom'
function Preferred() {
  return (
    <section>
        <div className="container py-6 md:py-12">
            <h1 className='nunito text-center text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold pb-6 md:pb-12'>Pick Your Preferred Investment</h1>
            <div className='grid md:grid-cols-3 grid-cols-2 gap-3 sm:gap-4 xl:pb-5'>
                <NavLink to="/mutual-funds" className='flex gap-4 sm:gap-8 px-2 md:px-2 lg:px-8 xl:px-12 py-2 md:py-3 border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_1} className='h-8 w-8  mt-2 lg:mt-0 md:ml-3 lg:ml-0 md:my-2 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito md:py-0 lg:py-0'>Mutual Funds SIP / Lumpsum</p>
                </NavLink>
                <NavLink to="/demat-page" className='flex gap-4 sm:gap-8 px-2 lg:px-8 xl:px-12 py-2 md:py-3 border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_2} className='h-8 w-8  md:my-0 mt-2 lg:mt-0 md:ml-3 lg:ml-0 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito py-2 md:py-3'>Equity / Demat</p>
                </NavLink>
                <NavLink to="/corporate-fd" className='flex gap-4 sm:gap-8 px-2 lg:px-8 xl:px-12 py-2 md:py-3 border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_1} className='h-8 w-8  md:my-2 mt-2 lg:mt-0 md:ml-3 lg:ml-0 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito py-2.5 md:py-3'>Fixed Deposits</p>
                </NavLink>
                <NavLink to="/pms" className='flex gap-3 sm:gap-7 px-2 lg:px-8 xl:px-12 py-2 md:py-1 xl:py-2  border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_2} className='h-8 w-8  md:my-0 mt-2 md:mt-4 lg:mt-0 md:ml-3 lg:ml-0 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito xl:py-1'>Portfolio Management Services</p>
                </NavLink>
                <NavLink to="/p2p" className='flex gap-4 sm:gap-8 px-2 lg:px-8 xl:px-12 py-2 md:py-3 border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_1} className='h-8 w-8  md:my-2 mt-2 lg:mt-0 md:ml-3 lg:ml-0 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito py-3'>P2P Lending</p>
                </NavLink>
                <NavLink to="/againt-shares" className='flex gap-3 sm:gap-8 px-1 lg:px-8 xl:px-12 py-2 md:py-1 border-2 border-custom rounded-lg h-20 '>
                    <img src={icon_3} className='h-8 w-8  md:my-0 mt-2 md:mt-4 lg:mt-2 md:ml-3 lg:ml-0 lg:w-fit lg:h-fit'/>
                    <p className='xl:text-xl text-sm md:text-base font-bold nunito py-2 md:py-3 xl:py-5'>Loan Against MF / Shares</p>
                </NavLink>
            </div>
        </div>
    </section>
  )
}

export default Preferred