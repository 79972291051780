import React from 'react'

function BenefitsCorBond() {
  return (
    <section className='typesofaif_bg'>
    <div className='container py-6 md:py-12'>
            <h1 className='xl:text-4xl text-2xl md:text-3xl font-bold nunito text-white text-center pb-2'>Benefits of Corporate Bonds</h1>
            <div className='grid md:grid-cols-3 gap-7 xl:py-16 py-6'>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Regular Income</p>
                    <p className='text-base font-normal inter'>Corporate bonds provide a steady stream of income through interest payments, which is attractive to income-seeking investors.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Diversification</p>
                    <p className=' text-base font-normal inter'>Investing in corporate bonds adds diversification to your investment portfolio, as they have different risk and return profiles compared to other asset classes.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 xl:h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Potential Wealth Creation</p>
                    <p className=' text-base font-normal inter'>Corporate bonds can appreciate over time, providing
                    capital gains opportunities.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenefitsCorBond