import React from 'react'
import icon_1 from '../assets/Icons/CompanyIcons/icon-1.svg'
import icon_2 from '../assets/Icons/CompanyIcons/icon-2.svg'
import icon_3 from '../assets/Icons/CompanyIcons/icon-3.svg'
import icon_4 from '../assets/Icons/CompanyIcons/icon-4.svg'
import icon_5 from '../assets/Icons/CompanyIcons/icon-5.svg'
import icon_6 from '../assets/Icons/CompanyIcons/icon-6.svg'
import icon_7 from '../assets/Icons/CompanyIcons/icon-7.svg'
import icon_8 from '../assets/Icons/CompanyIcons/icon-8.svg'
import icon_9 from '../assets/Icons/CompanyIcons/icon-9.svg'
import icon_10 from '../assets/Icons/CompanyIcons/icon-10.svg'
import icon_11 from '../assets/Icons/CompanyIcons/icon-11.svg'
import icon_12 from '../assets/Icons/CompanyIcons/icon-12.svg'
import icon_13 from '../assets/Icons/CompanyIcons/icon-13.svg'
import icon_14 from '../assets/Icons/CompanyIcons/icon-14.svg'
import icon_15 from '../assets/Icons/CompanyIcons/icon-15.svg'
import icon_16 from '../assets/Icons/CompanyIcons/icon-16.svg'
import icon_17 from '../assets/Icons/CompanyIcons/icon-17.svg'
import icon_18 from '../assets/Icons/CompanyIcons/icon-18.svg'
function CompanyIcons() {
  return (
    <section>
        <div className='container py-16 xl:py-24'>
            <h1 className='text-center text-2xl md:text-3xl xl:text-4xl font-bold nunito py-1'>Make Wise Investments, Enjoy a Richer Life</h1>
            <p className='text-center inter text-base font-normal '>Enjoy stable returns with our selection of government and corporate bonds.</p>
            <div className='flex flex-wrap justify-center gap-7 py-16'>
                <img src={icon_1}/>
                <img src={icon_2}/>
                <img src={icon_3}/>
                <img src={icon_4}/>
                <img src={icon_5}/>
                <img src={icon_6}/>
                <img src={icon_7}/>
                <img src={icon_8}/>
                <img src={icon_9}/>
                <img src={icon_10}/>
                <img src={icon_11}/>
                <img src={icon_12}/>
                <img src={icon_13}/>
                <img src={icon_14}/>
                <img src={icon_15}/>
                <img src={icon_16}/>
                <img src={icon_17}/>
                <img src={icon_18}/>
            </div>
            <div className='flex justify-center '>
                <button className='sign_btn text-lg font-bold text-white bg-custom py-2 px-6 rounded-lg '>
                <span>Start from today</span></button>
            </div>
        </div>
    </section>
  )
}

export default CompanyIcons