import React, { useState } from 'react';

const TypesofAif = () => {
  const [activeCategory, setActiveCategory] = useState(1);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <section className='typesofaif_bg'>
      <div className="container text-white py-10 xl:py-20">
        <div className='text-center'>
          <h1 className="xl:text-4xl text-2xl md:text-3xl font-bold nunito pb-2">Types of AIF</h1>
          <p className="text-base font-normal inter pb-6">In India, Alternate Investment Funds (AIFs) are classified into three categories</p>
        </div>
        <div className="lg:flex">
          <div className="xl:w-1/5 flex flex-col gap-6">
            <div
              className={`lg:h-36 h-16 lg:w-52 xl:w-72 rounded-xl flex justify-center items-center cursor-pointer ${activeCategory === 1 ? 'bg-[#DF5759]' : 'bg-[#014579]'}`}
              onClick={() => handleCategoryClick(1)}
            >
              <h2 className="xl:text-4xl text-2xl font-bold nunito">Category - 1</h2>
            </div>
            <div
              className={`lg:h-36 h-16 lg:w-52 xl:w-72 rounded-xl flex justify-center items-center cursor-pointer ${activeCategory === 2 ? 'bg-[#DF5759]' : 'bg-[#014579]'}`}
              onClick={() => handleCategoryClick(2)}
            >
              <h2 className="xl:text-4xl text-2xl font-bold nunito">Category - 2</h2>
            </div>
            <div
              className={`lg:h-36 h-16 lg:w-52 xl:w-72 rounded-xl flex justify-center items-center cursor-pointer ${activeCategory === 3 ? 'bg-[#DF5759]' : 'bg-[#014579]'}`}
              onClick={() => handleCategoryClick(3)}
            >
              <h2 className="xl:text-4xl text-2xl font-bold nunito">Category - 3</h2>
            </div>
          </div>
          <div className="lg:ml-8 py-10 lg:py-0">
            {activeCategory === 1 && (
              <div className="lg:pl-8">
                <p className='inter font-normal text-base'>Category 1 AIFs focus on investing in small and medium enterprises (SMEs), start-ups, and new economically viable businesses that show high growth potential. This category includes the following types of AIFs:</p>
                <ol className="">
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>1. Venture Capital Funds (VCF)</span> <br /> These funds provide financing to new-age entrepreneurial firms during their initial stages and invest in start-ups with significant growth prospects.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>2. Angel Funds</span><br />Angel funds invest in budding start-ups that are not receiving funding from VCFs and aim to bring early business management experience along with their investment.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>3. Infrastructure Funds</span><br />These funds invest in infrastructure companies involved in railway construction, port construction, etc. They suit investors who are bullish on infrastructure development.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>4. Social Venture Funds</span><br />These funds invest in socially responsible businesses and aim to generate decent returns for investors while contributing to social causes.
                  </li>
                </ol>
              </div>
            )}
            {activeCategory === 2 && (
              <div className="lg:pl-8">
              <p className='inter font-normal text-base'>Category 2 AIFs encompass the following types of funds:</p>
                <ol className="">
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>1. Private Equity Funds</span> <br /> These funds invest in unlisted private companies that face difficulty in raising funds through equity and debt instruments. They generally have a lock-in period ranging from 4 to 7 years.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>2. Debt Funds</span><br />Debt funds primarily invest in debt securities of unlisted companies that follow good corporate governance models and exhibit high growth potential. These funds are considered riskier for conservative investors due to the low credit rating of these companies. The accumulated money in debt funds cannot be utilized to extend loans.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>3. Fund of Funds</span><br />Fund of Funds do not have an investment portfolio but concentrate on investing in different AIFs, providing investors with diversification and exposure to multiple AIFs.
                  </li>
                 
                </ol>
              </div>
            )}
            {activeCategory === 3 && (
              <div className="lg:pl-8">
              <p className='inter font-normal text-base'>Category 3 AIFs include the following types of funds:</p>
                <ol className="">
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>1. Private Investment in Public Equity (PIPE) Funds</span> <br /> PIPE funds invest in shares of publicly traded companies at discounted prices, offering a more convenient investment option than secondary issues.
                  </li>
                  <li className="mt-6 text-base inter font-normal text-white">
                    <span className='nunito text-xl xl:text-3xl font-bold '>2. Hedge Funds</span><br />Hedge funds pool money from accredited investors and institutions, investing in domestic and international debt and equity markets. They adopt an aggressive investment strategy to generate returns for investors, but can be expensive in terms of the asset management fees charged by fund managers.
                  </li>
                 </ol>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TypesofAif;
