import React from 'react'
import Navbar from '../Components/Navbar'

function CommingSoonPage() {
  return (
   <>
      <section className='comming_soon_bg'>
         <div className='container h-screen xl:w-screen flex justify-center items-center '>
             {/* <div className='md:flex md:gap-10 mt-72 md:mt-80'>
                 <div>
                   <input
                    placeholder='Please enter your e-mail'
                    className='py-3 px-8 border-2 xl:w-[500px] rounded-3xl border-[#A5A8AB] placeholder:outfit placeholder:font-normal'
                   />
                 </div>
                 <div className='bg-black border rounded-3xl xl:w-min mt-5'>
                    <button className='outfit text-lg font-normal text-center px-8 py-3 text-white'>Subscribe</button>
                 </div>
             </div> */}
         </div>
      </section>

   </>
  )
}

export default CommingSoonPage