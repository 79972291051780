import React from 'react'
import btn_arrow from '../assets/Icons/button-arrow.svg'
import bank_icons from '../assets/Icons/Bank-icons.svg'
function MutualFundPartners() {
  return (
   <section className='fund-partners-bg-image'>
    <div className='container py-4 md:flex justify-between'>
            {/* Left contain */}
            <div className='xl:w-2/5 lg:w-1/2 md:w-9/12 mt-10 md:mt-6 xl:mt-20 xl:flex xl:flex-col gap-6'>
                <h1 className='text-white nunito text-2xl xl:text-4xl lg:text-3xl font-bold'>Partnered with 40+ Leading Mutual Fund Providers</h1>
                <p className='text-white inter text-base font-normal py-5  xl:py-0 xl:-mt-3'>We work with over 40 top mutual fund partners, offering a wide range of investment options to suit your financial goals. Our strong partnerships ensure you receive the best mutual fund investments, tailored to meet your specific needs. Trust in our network to help you achieve your financial objectives.</p>
                {/* <div className='flex gap-2 '>
                    <button className='text-[#DF5759] text-lg sm:text-xl font-medium asap'>View All Mutual Fund Partners</button>
                    <img src={btn_arrow} className='mt-1'/>
                </div> */}
            </div>

            {/* Right contain */}
            <div className='py-10 md:py-6 lg:py-0'>
                <img src={bank_icons}/>
            </div>
    </div>
   </section>
  )
}

export default MutualFundPartners