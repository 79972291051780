import React from 'react'
import right_img from '../assets/Images/soverign-bond-hero-right.webp'
import { Link } from 'react-router-dom'
function SovereignGoldHero() {
  return (
    <>
        <section className='soverign_bond_bg'>
            <div className='container py-10 xl:py-16 xl:mt-3'>
                <div className='xl:w-1/2 lg:w-8/12 md:w-4/5'>
                    <h1 className='text-white nunito font-bold text-2xl lg:text-3xl xl:text-5xl pb-3'>Sovereign Gold Bonds<br/> (SGB)</h1>
                    <p className='text-white inter font-normal xl:text-lg pb-4'>Sovereign Gold Bonds (SGB) are government securities denominated in grams of gold, issued by the Reserve Bank of India (RBI) on behalf of the Government of India. Investing in SGBs helps diversify your portfolio by adding an asset class like gold.</p>
                    <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>

                    </div>
                </div>
        </section>
          {/* Second part */}
          <section className='container md:flex gap-3 py-8  md:py-16'>
                <div className='xl:w-3/5 flex flex-col justify-center'>
                    <h2 className='nunito text-[#3C3C3C] xl:text-[40px] text-2xl lg:text-3xl font-bold '>What are Sovereign Gold Bonds (SGB)?</h2>
                    <p className='text-[#6A6A6A] inter font-base font-normal py-3'>SGBs are a financial instrument introduced by the Government of India to provide individuals the opportunity to invest in gold without physically owning it. They are issued in multiple tranches throughout the year and carry a specific tenor, usually ranging from 8 to 12 years.</p>
                    <p className='text-[#6A6A6A] inter font-base font-normal '>SGBs offer a return linked to the price of gold and provide investors with an assured interest rate of 2.50% per annum.</p>
                </div>
                <div className='xl:w-2/5 mt-6 sm:mt-0'>
                    <img src={right_img}/>
                </div>
            </section>
    </>
  )
}

export default SovereignGoldHero