import React from 'react'
import icon_1 from '../assets/Icons/Eqiuity-cash.svg'
import icon_2 from '../assets/Icons/Future-icon.svg'
import icon_3 from '../assets/Icons/Equity-trading-icon.svg'
import icon_4 from '../assets/Icons/Traded_fund.svg'

function BestInsurance() {
  return (
   <section >
        <div className='container bg-[#F5F5F5] pt-16 pb-10 xl:pb-0'>
            <h1 className='text-center nunito text-2xl md:text-3xl xl:text-4xl font-bold'>Let's find you the Best Equity</h1>
            <div className='grid lg:grid-cols-4 grid-cols-2 md:gap-4 lg:py-16 md:px-20 lg:px-0 xl:px-20 mt-5 xl:mt-0'>
                <div className='flex flex-col justify-center items-center w-40 md:w-60 h-32 rounded-lg cursor-pointer hover:bg-white mt-3 sm:mt-0'>
                    <img src={icon_1}/>
                    <p className='md:text-lg text-base font-semibold plus-jakarta-san text-center'>Equity Intraday Trading</p>
                </div> 
                <div className='flex flex-col justify-center items-center w-40 md:w-60 h-32 rounded-lg cursor-pointer hover:bg-white'>
                    <img src={icon_2}/>
                    <p className='md:text-lg text-base font-semibold plus-jakarta-san'>Futures and Options</p>
                </div> 
                <div className='flex flex-col justify-center items-center w-40 md:w-60 h-32 rounded-lg cursor-pointer hover:bg-white'>
                    <img src={icon_3}/>
                    <p className='md:text-lg text-base font-semibold plus-jakarta-san'>Equity Cash Market</p>
                </div> 
                <div className='flex flex-col justify-center items-center text-center w-40 md:w-60 h-32 rounded-lg cursor-pointer lg:mt-3 hover:bg-white'>
                    <img src={icon_4}/>
                    <p className='md:text-lg text-base font-semibold plus-jakarta-san'>Exchange Traded Funds (ETFs)</p>
                </div> 
            </div>
        </div>
   </section>
  )
}

export default BestInsurance