import React from 'react'
import img_1 from '../assets/Images/SuccessWill-hero-right-img.webp'
import { Link } from 'react-router-dom'
function SuccessHero() {
  return (
    <>
    <section className='success_will_hero_bg xl:mt-3'>
         <div className='container py-10 xl:py-24'>
            <div className='lg:w-3/5'>
                 <h1 className='lg:text-3xl xl:text-5xl text-2xl md:text-3xl  text-white font-bold nunito'>Succession & Will</h1>
                 <p className='xl:text-lg text-sm md:text-base py-2  text-white inter font-normal'>Planning for the future is essential to ensure that your hard-earned assets are passed on according to your wishes. We offer comprehensive Succession and Will services to help you protect your legacy and provide peace of mind for you and your loved ones.</p>
                 <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
            </div>
         </div>
     </section>

     <section className='container bg-white md:flex gap-4 xl:gap-16 py-6 md:py-10 xl:py-16'>
                 {/* Left section */}
                 <div className='flex flex-col justify-center'>
                     <h1 className='xl:text-4xl lg:text-3xl text-2xl md:text-2xl font-bold nunito'>Why Plan Your Succession and Will?</h1>
                     {/* <p className='text-base font-normal text-[#6A6A6A] inter py-3'>Peer-to-peer (P2P) lending enables individuals to obtain loans directly from other individuals, cutting out the financial institution as the middleman. Websites that facilitate P2P lending have greatly increased their adoption as an alternative method of financing.</p> */}
                     {/* <p className='text-base font-normal text-[#6A6A6A] inter'>P2P lending is also known as “social lending” or “crowd lending.” It has only been around since 2005, but the crowd of competitors already includes Prosper, Lending Club, Upstart, and Funding Circle.</p> */}
                     <ul className='xl:mt-2'>
                         <li className='text-base font-normal text-[#6A6A6A] inter pt-3'>1. Control Over Your Assets: Ensure your assets are distributed according to your wishes.Avoid Family Disputes: Clear and legally binding documentation can prevent misunderstandings and disputes among heirs.</li>
                         <li className='text-base font-normal text-[#6A6A6A] inter pt-3'>2. Tax Efficiency: Proper planning can minimize the tax burden on your estate</li>
                         <li className='text-base font-normal text-[#6A6A6A] inter pt-3'>3. Protect Your Loved Ones: Provide financial security for your family and dependents.</li>
                     </ul>
                 </div>
                 {/* Right side */}
                 <div className='py-6 xl:w-4/5 xl:py-0'>
                     <img src={img_1} className=''/>
                 </div>
     </section>

 </>
  )
}

export default SuccessHero