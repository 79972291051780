import React from 'react'
import left_img from '../assets/Images/WhyAIF-img.png'

function WhyAif() {
  return (
   <section>
    <div className='container py-12 md:py-20 md:flex md:gap-5 xl:gap-16'>
            <div className='xl:w-1/2 md:w-full pb-6'>
                <img src={left_img}/>
            </div>
            <div className='flex flex-col justify-center  '>
                <h1 className='nunito xl:text-4xl text-2xl font-bold text-[#3C3C3C] pb-8 xl:pb-10'>Why invest in AIFs?</h1>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Diversification</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>By investing in AIFs, investors can gain exposure to alternative strategies, sectors, or geographic regions that may have lower correlation with traditional investments.</p>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Higher Potential Returns</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>These funds often employ specialized investment strategies or have access to unique investment opportunities that can generate alpha (excess returns).</p>
                <p className='nunito xl:text-2xl text-xl font-bold text-[#3C3C3C]'>Access to Expert Management</p>
                <p className='inter text-base font-normal text-[#6A6A6A]'>AIFs are typically managed by professional fund managers or investment teams with specialized expertise in their respective strategies.</p>

            </div>
    </div>
   </section>
  )
}

export default WhyAif