import React from 'react'
import mail_icon from '../assets/Icons/gmail-icon.svg'
import location_icon from '../assets/Icons/Location-icon.svg'
import ContactUsInputField from '../Components/ContactUsInputField'
import { Link } from 'react-router-dom'
function ContactPage() {
  return (
    <>
        <section className='contact_page_bg xl:mt-3'>
            <div className='container py-16'>
                    <div className='xl:w-3/5'>
                        <h1 className='xl:text-5xl lg:text-3xl text-2xl text-white font-bold nunito'>Do you have any questions? Our team is here to help!</h1>
                        <p className='lg:text-lg text-xs md:text-base pb-6 pt-2 text-white inter font-normal xl:leading-6'>Portfolio Management Services (PMS) offer personalized and professional management of your investment portfolio.</p>
                        <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
                    </div>
            </div>
        </section>
        <section>
            <div className='container py-12'>
                 <div className='flex flex-col gap-4 justify-center items-center text-center'>
                    <h2 className='text-[#014579] xl:text-4xl lg:text-2xl text-xl font-bold nunito'><span className='text-custom'>Need further assistance?</span> Don't worry, our customer care team is <br/>just a call or message away</h2>
                    <p className='text-[#6A6A6A] text-base font-normal inter'>Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio,</p>
                 </div>
                  {/* Mail Icon */}
                  <div className='xl:w-96 xl:h-20 flex justify-center items-center mt-5 mx-auto bg-white border rounded-2xl shadow-xl'> 
                    <div className='flex gap-3 justify-center items-center '>
                        <img src={mail_icon}/>
                        <p className='text-[#4E4E4E] text-lg xl:text-2xl font-normal nunito'>contactus@oiawealth.com</p>
                    </div>
                  </div>
                  {/* Addresses */}
                    <h4 className='text-[#014579] text-xl lg:text-2xl xl:text-4xl font-bold nunito text-center pt-14 pb-14 xl:pt-24 xl:pb-8'><span className='text-custom'>Registered</span> and Corporate offices</h4>
                    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 pb-28'>
                        <div className='flex gap-3 xl:h-28 rounded-2xl shadow-xl border bg-white py-6 px-10'>
                            <img src={location_icon} className='w-10 h-10'/>
                            <p className='text-[#4E4E4E] text-xl font-normal nunito'>406 Gurunanak Plaza, Lodha Kothi, Station Road, Kota 324002, Rajasthan</p>
                        </div>
                        <div className='flex gap-3 xl:h-28 rounded-2xl shadow-xl border bg-white py-6 px-10'>
                            <img src={location_icon} className='w-10 h-10'/>
                            <p className='text-[#4E4E4E] text-xl font-normal nunito'>Spark & Associates, F- 371, Indra Vihar, Kota 324005, Rajasthan</p>
                        </div>
                     </div>
            </div>
            <div className='xl:pb-28'>
                <ContactUsInputField/>
            </div>
        </section>
    </>
  )
}

export default ContactPage