import React from 'react'
import img_refer from '../assets/Images/refer-img.webp'
function ReferandSubBroker() {
  return (
    <>
        {/* Refer and Become a Sub-broker */}
    <section>
         <p className='container nunito text-center text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold py-8'>Choose Your Preferred Partnership Model</p>
        <div className='container grid md:grid-cols-2 gap-8 pb-10 xl:pb-20'>
            <div className='xl:h-32 h-16 w-full bg-[#014579] flex justify-center items-center rounded-xl'>
                <p className='nunito xl:text-4xl text-lg font-bold text-white'>Refer Leads & earn Referral Fees</p>
            </div>
            <div className='xl:h-32 h-16 w-full bg-custom flex justify-center items-center rounded-xl'>
                <p className='nunito xl:text-4xl text-lg font-bold text-white'>Become a Sub-Broker</p>
            </div>
        </div>
    </section>

    <section className='bg-[#F5F5F5] py-8 xl:py-20'>
        <div className='container md:flex justify-between md:gap-5'>
            <div>
                <img src={img_refer}/>
            </div>
            <div className='lg:w-1/2 flex flex-col gap-4 justify-center'>
                <p className='nunito lg:text-2xl xl:text-4xl text-xl font-bold text-[#3C3C3C] xl:leading-10 mt-6 sm:mt-0'>1. Highest payout in Industry
                    Transparent % age based sharing model.
                </p>
                <p className='nunito lg:text-2xl xl:text-4xl text-xl font-bold text-[#3C3C3C]'>2. You gain from our large scale & higher Slabs.</p>
                 {/* <div className='pt-8'>
                   <button className='bg-custom sign_btn py-2 px-8 text-lg font-bold roboto text-white rounded-lg '>
                  <span>Register Now</span></button>
                 </div>    */}
            </div>
        </div>
    </section>

    </>
  
  )
}

export default ReferandSubBroker